import React, { useState } from 'react';
import './index.sass';
import { release, SoundtrackItem, SoundtrackPlaylist, track } from '../../models/soundModels';
import { ControlsPlay } from '../AudioPlayer/controls';


interface OtherRel {
  releases: Array<release>,
  t: SoundtrackItem|null,
  c: release|null,
  pct: number,
  paused: boolean,
  onTracks?: (tracks:SoundtrackPlaylist, index:number, action?:string) => void,
  onOpen?: (r:release) => void,
  onPause?: (pause:boolean) => void,
}


function OtherReleases(props:OtherRel) {
  
  const playAlbum = (r:release|undefined) => {
    if (r && isTracked(r) && !props.paused) {
      if (props.onPause) props.onPause(true);
    } else if (r && isTracked(r) && props.paused) {
      if (props.onPause) props.onPause(false);
    } else {
      const trcks:SoundtrackPlaylist = [];
      r?.tracks.forEach((t) => {
        trcks.push({
          name: t.name,
          album: r.name,
          tag: r.name,
          lyrics: t.lyrics,
        });
      });
      emitTracks(trcks);
    }
  }

  const emitTracks = (tracks:SoundtrackPlaylist) => {
    if (props.onTracks) props.onTracks(tracks, 0, 'playnow');
  }

  const emitOpen = (r:release) => {
    if (props.onOpen) props.onOpen(r);
  }

  const isOnAlbum = (n:string, trcks:Array<track>):boolean => {    
    return trcks.map(t => t.name).indexOf(n) > -1
  }

  const isTracked = (r:release) => {
    return props.t && isOnAlbum(props.t.name, r.tracks)
  }

  return (
    <div className="OtherReleases">
      <div className='releases'>
        { props.releases.map((r:release, i:number) => (
          <div key={i} className={`release ${props.t && isOnAlbum(props.t.name, r.tracks) ? 'playing' : ''} ${props.c?.name === r.name ? 'selected' : ''}`}> 
            <img src={r.cover} alt="" className='transition' onClick={()=>emitOpen(r)}/>
            <div className='name transition'>{r.name}</div>
            <div className='controls transition'>
              <ControlsPlay filled paused={ isTracked(r) && props.paused} tracked={isTracked(r)} onPlayPause={() => { playAlbum(r) }} pct={props.pct} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OtherReleases;

import React from 'react';
import './index.sass';

function Header() {

  return (
    <div className="Header">
      <svg width="141" height="209" viewBox="0 0 141 209" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M70.35 22.041C76.09 9.25702 88.9359 0.343994 103.847 0.343994C124.101 0.343994 140.545 16.788 140.545 37.043C140.545 52.02 131.554 64.914 118.678 70.616C131.554 76.318 140.545 89.212 140.545 104.189C140.545 119.167 131.554 132.061 118.678 137.763C131.554 143.465 140.545 156.359 140.545 171.336C140.545 191.591 124.101 208.031 103.847 208.031H36.7C16.446 208.031 0.00195312 191.591 0.00195312 171.336C0.00195312 156.206 9.17798 143.202 22.264 137.591C9.17798 131.979 0.00195312 118.975 0.00195312 103.845C0.00195312 88.868 8.99396 75.974 21.869 70.272C8.99396 64.57 0.00195312 51.676 0.00195312 36.699C0.00195312 16.444 16.446 0 36.7 0C51.7449 0 64.687 9.07302 70.35 22.041ZM36.7 67.147C53.505 67.147 67.1489 53.503 67.1489 36.699C67.1489 19.894 53.505 6.25 36.7 6.25C19.895 6.25 6.25195 19.894 6.25195 36.699C6.25195 53.503 19.895 67.147 36.7 67.147ZM36.7 73.397C19.895 73.397 6.25195 87.04 6.25195 103.845C6.25195 120.65 19.895 134.294 36.7 134.294C53.505 134.294 67.1489 120.65 67.1489 103.845V101.3H67.2609C68.7359 82.394 84.5649 67.491 103.847 67.491C120.652 67.491 134.295 53.848 134.295 37.043C134.295 20.238 120.652 6.59399 103.847 6.59399C87.0419 6.59399 73.3989 20.238 73.3989 37.043H73.397C73.212 57.139 56.84 73.397 36.7 73.397ZM51.137 137.591C59.659 141.245 66.5229 148.034 70.2739 156.504C75.9759 143.629 88.8699 134.638 103.847 134.638C120.652 134.638 134.295 120.994 134.295 104.189C134.295 87.384 120.652 73.741 103.847 73.741C87.0419 73.741 73.3989 87.384 73.3989 104.189V106.779H73.283C72.185 120.641 63.369 132.345 51.137 137.591ZM103.847 140.888C87.0419 140.888 73.3989 154.531 73.3989 171.336H67.1489C67.1489 154.531 53.505 140.888 36.7 140.888C19.895 140.888 6.25195 154.531 6.25195 171.336C6.25195 188.141 19.895 201.781 36.7 201.781H103.847C120.652 201.781 134.295 188.141 134.295 171.336C134.295 154.531 120.652 140.888 103.847 140.888Z"/>
      </svg>
    </div>
  );
}

export default Header;

import React, { useRef, useState } from 'react';

import './App.sass';
import Footer from './components/footer';
import Header from './components/header';
import OtherReleases from './components/other-releases';
import releases from './data/releases';
import Wait from './components/new';
import AudioPlayer from './components/AudioPlayer';
import { release, SoundtrackItem, SoundtrackPlaylist } from './models/soundModels';
import { findIndex } from 'lodash';
import MoreInfo from './components/MoreInfo';

function App() {

  const sideRef = useRef<HTMLDivElement>(null);
  const [audioRef, setAudioRef] = useState<HTMLAudioElement|null>(null);

  const [playlist, setPlaylist] = useState<{list:SoundtrackPlaylist, action:string, time:number}>({list: [], action: 'playnow', time:0});
  const [track, setTrack] = useState<SoundtrackItem|null>(null);
  const [offset, setOffset] = useState(100);
  const [width, setWidth] = useState(0);
  const [currentInfo, setCurrentInfo] = useState<release|null>(null)
  const [percent, setPercent] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [paused, setPaused] = useState(true);

  const addTracks = (list:SoundtrackPlaylist, index:number, action:string='none') => {  
    setInitialIndex(index);
    setPlaylist({list, action, time:new Date().getTime()});
  }  

  const handleTrack = (t:SoundtrackItem|null, audio:HTMLAudioElement) => {  
    setAudioRef(audio);      
    setTrack(t);
  }

  const handleOpen = (r:release|null) => {
    if (r) {
      setCurrentInfo(r);
      setWidth(sideRef.current?.getBoundingClientRect().width || 0);
      setOffset(-100);
    } else {
      setCurrentInfo(null);
      setOffset(0);
      setWidth(0);
    }
  }

  const getRelease = (t:SoundtrackItem):release => {
    const idx = findIndex(releases, (r) => r.tracks.map(tr=>tr.name).indexOf(t.name) > -1)
    return releases[idx];
  }

  const pausePlayer = (pause:boolean) => {
    if (pause) {
      audioRef?.pause()
    } else {
      audioRef?.play()
    }
  }

  const updateList = (list:SoundtrackPlaylist) => {
    setPlaylist({ list, action: 'none', time: 0 });
    if (list.length === 0) {
      audioRef?.pause();
      setInitialIndex(0);
      setPercent(0);
      setPaused(true);
      setTrack(null);      
    }
  }
  
  return (
    <div className="App">
      <div className='App__Main scrollable transition' style={{ width: `calc(100% - ${width}px)` }}>
        <Header/> 
        <Wait/>
        <OtherReleases paused={paused} pct={percent} c={currentInfo} t={track} releases={releases} onOpen={handleOpen} onTracks={addTracks} onPause={pausePlayer}/>
        <Footer/>
      </div>
      <div ref={sideRef} className='App__More transition'style={{ transform: `translateX(${offset}%)` }}>
        <MoreInfo paused={paused} pct={percent} t={track} current={currentInfo} onClose={handleOpen} onTracks={addTracks} onPause={pausePlayer}/>
        <button onClick={()=> handleOpen(null) }>FECHAR</button>
      </div>
      {
        playlist.list.length > 0 && 
          <AudioPlayer
            playlist={playlist}
            initialTrack={initialIndex}
            onTrack={handleTrack} 
            onMore={(t:SoundtrackItem) => handleOpen(getRelease(t))}
            onUpdate={(data)=>{ setPercent(data.pct); setPaused(data.isPaused); }}
            onUpdateList={updateList}/>      
      }
    </div>
  );
}

export default App;

import cover_nascedouro from '../assets/nascedouro.jpg';
import cover_segundapessoa from '../assets/segundapessoa.jpg';
import cover_metal from '../assets/metal.jpg';
import cover_o_resto from '../assets/o_resto.jpg';
import cover_doisquartos from '../assets/doisquartos.jpg';
import cover_terceirapessoa from '../assets/terceirapessoa.jpg';
import cover_primeirapessoa from '../assets/primeirapessoa.jpg';
import cover_trespasse from '../assets/trespasse.png';
import cover_desmonte from '../assets/desmonte.jpg';
import cover_clutching from '../assets/clutching.jpg';
import cover_final from '../assets/final.jpg';

const releases = [
    {
        name: 'Nascedouro',
        cover: cover_nascedouro,
        release: '2024-10-10',
        tracks: [
            {
                name: 'Nascedouro',
                video: 'CRFuawcQwoc',
                lyrics: `
                Eu nasci errado\n
                estive amarrado\n
                madeira e cupim\n\n
                Me desfiz calado\n
                meu significado\n
                Roubado de mim\n\n
                Ando atordoado\n
                meu torto arado\n
                rasgando o jardim\n\n
                Eu sou emprestado\n
                o couro esticado\n
                me fez tamborim\n\n
                Canto tão sonhado\n
                num sonho inventado\n
                escrito em marfim\n\n
                Feito rebuscado\n
                o falso sagrado\n
                alçado pra mim\n\n
                Amaldiçoado\n
                desafortunado\n
                tim-tim por tim-tim\n\n
                Dote ou predicado:\n
                O tiro atirado\n
                não é de festim.\n\n
                No fim  me vejo entre o não\n
                o sim, o pé, o chão... Me falta pertencer\n
                Assim; se manda a tradição\n
                eu sigo pelo não e busco renascer\n\n
                No fim me vejo pelo chão\n
                o sim, a fé, o não... Toque de recolher\n
                Assim; fruto da expedição\n
                Me sobra coração sem ter onde bater`,
            }
        ],
    },
    {
        name: 'Segunda Pessoa',
        cover: cover_segundapessoa,
        release: '2023-12-31',
        tracks: [
            {
                name: 'Segunda Pessoa',
                lyrics: `
                Saiba que eu sou você\n
                Longe de toda ilusão\n
                Lava esse corte e me dá a mão\n
                Cuida, pra que o jardim\n
                Nunca deixe de ser seu\n
                Seu lugar no mundo são seus pés no\n
                Chão que nasce, e morre, e nasce e morre\n\n
                E corta\n
                O que\n
                Te faz sentir essa aflição\n
                Picota e desenha o chão\n
                Como um de nós\n\n
                Atento\n
                Ao frio\n
                Mas não com medo de tremer\n
                A gente fez por merecer\n
                Te amo até o fim\n\n
                Me encontra no jardim\n
                Pra gente enfim brincar`,
            }
        ],
    },
    {
        name: 'Metal',
        cover: cover_metal,
        release: '2023-11-29',
        tracks: [
            {
                name: 'Metal',
                video: 'bFPX3R928ow',
                lyrics: `
                Desfaz a sua voz\n
                Te rompe pelo medo\n
                Incessante\n\n
                Agindo feito máquina\n
                Martelando a nossa dor\n
                Cobrindo dessa pátina\n
                A porta e o invasor\n\n
                Fabrica a sua fé\n
                Anula o desejo\n
                Soberano\n\n
                E corta como um coração\n
                Que bate feito um punhal\n
                Que sente nossa emoção\n
                Mas mata frio como metal\n\n
                Brutal\n
                Devorando apressado o que sai da minha boca\n
                Eu não quero mais\n
                Eu não quero mais\n
                Paz\n
                Se o silêncio é o preço pra esse fim\n\n
                Vão seus sonhos derramar\n
                Até tudo fracassar\n
                Te lembrando que esse dia ia chegar\n
                Até quando mascarar?\n
                Nada vai nos ensinar\n
                Compram tudo, mas se esquecem de pagar\n\n
                Brutal\n
                Devorando apressado o que sai da minha boca\n
                Eu não quero mais\n
                Eu não quero mais\n
                Paz\n
                Se o silêncio é o preço pra esse fim\n
                Eu não quero mais\n
                Eu não quero mais\n
                Brutal\n
                Devorando apressado o que sai da minha boca\n
                Eu não quero mais\n
                Eu não quero mais\n
                Paz\n
                Se o silêncio é o preço pra esse fim`,
            }
        ],
    },
    {
        name: 'O Resto',
        cover: cover_o_resto,
        release: '2023-10-26',
        tracks: [
            {
                name: 'O Resto',
                video: 'r3NVhewHnQU',
                lyrics: `
                Meu corpo é uma roupa\n
                Que nunca me serviu\n
                Onde ele falta, eu sobro\n
                A via e a cilada\n
                A vala que caí\n\n
                Me abraça e me diz que nada\n
                Foi feito pra mim\n\n
                Meu corpo é uma roupa\n
                Que eu não escolhi\n
                Onde ele fala, eu calo\n
                A cura e o sintoma\n
                O cheio e o vazio\n\n
                E me diz que nada\n
                Pode ser assim\n\n
                Talvez quando eu souber largar\n
                Meu corpo enfim aprenda a andar\n
                E então, quando eu souber andar\n
                Meu corpo, assim, saiba largar\n
                Do quanto andei sem ver chegar\n\n
                Talvez se eu não quiser chegar\n
                O resto baste`,
            }
        ],
    },
    {
        name: 'Dois Quartos',
        cover: cover_doisquartos,
        release: '2023-10-11',
        tracks: [
            {
                name: 'Dois Quartos',
                video: 'RTVKXV0tClE',
                lyrics: `
                Sinto romper o papel\n
                No céu da minha boca\n
                A sala de outra cor\n
                Melhor assim\n\n
                Vejo desfazer o véu\n
                Me incomoda a roupa\n
                Estrelas vão se pôr\n
                No chão\n
                E eu ainda nem saí do quarto\n\n
                Separo\n
                Outro quarto\n
                E reparo\n
                Que o infarto\n
                Pode me bater na porta a qualquer hora, então eu vou\n\n
                Pra rua\n
                Perdido\n
                E percebo só ali\n
                Que o frio chegou\n\n
                Vejo tudo em neon\n
                Acho que exagerei\n
                Na ousadia\n\n
                Decidir é mesmo um dom\n
                Uma coisa que não sei\n
                Que ironia\n\n
                Penso em seguir o som\n
                Perceber que já cheguei\n
                E não sabia\n\n
                Sinto aquele vento bom\n
                Que eu quase estraguei\n
                Me dizendo que eu\n\n
                Ia perder a noite\n
                Eu ia perder o sono\n
                Olhando a noite a passar\n\n
                Ah, eu ia perder a noite\n
                Eu ia perder as estrelas no chão\n
                Sem nunca chegar\n\n
                Eu ia perder a noite\n
                Eu ia perder a noite\n
                Eu ia perder a noite\n
                Eu ia perder a noite`,
            }
        ],
    },
    {
        name: 'Terceira Pessoa',
        cover: cover_terceirapessoa,
        release: '2023-08-24',
        tracks: [
            {
                name: 'Terceira Pessoa',
                video: '45v0IelZih8',
                lyrics: `                
                Corre\n
                Mesmo sem ter caminho\n
                Pra deixar pra trás\n
                O tempo\n
                E se encher da vontade\n
                De ver o corpo abandonar a alma\n
                E agarrar num sonho\n
                Perverso\n\n
                Inventa um fim\n
                Sem cor nem dor\n
                Sem gozo ou sofrimento\n
                Queima o jardim\n
                O chão e a flor\n
                Se veste de cimento e\n
                Cai\n
                Num frio azul profundo\n
                Cai\n
                Num frio azul profundo\n\n
                Cai\n
                Profundo\n\n
                Se corta\n
                Se corta\n
                Febril\n\n
                Cai\n
                Num frio azul profundo\n\n
                Se corta\n\n
                Fere tão profundo que\n
                Ve que é tudo ilusão\n
                O corte, o fundo e a solidão\n
                Deixa chover no jardim\n
                Finge que o sol é seu\n
                E por um segundo sente os pés no\n
                Chão que nasce, e morre, e nasce e morre\n\n
                O azul profundo enfim\n
                Fez-se parte da ilusão\n
                Projetando a sombra dentro de um clarão`,
            }
        ],
    },
    {
        name: 'Primeira Pessoa',
        cover: cover_primeirapessoa,
        release: '2023-06-21',
        tracks: [
            {
                name: 'Primeira Pessoa',
                video: 'nY0OleYaGwk',
                lyrics: `
                Me corta\n
                Sentir\n
                No corpo inteiro a aflição\n
                De não ouvir a vibração\n
                Da minha voz\n\n
                Rebento\n
                Vadio\n
                Tão novo pra reconhecer\n
                O que vai me emudecer\n
                E me fazer feroz\n\n
                Infância foi veloz\n
                Em me abandonar\n\n
                A porta\n
                Ali\n
                Exposto pra que a invasão\n
                Viesse como condição\n
                Pra ter um fim\n\n
                Intento\n
                Febril\n
                Dobrar o corpo sem ranger\n
                Forçar a alma a esquecer\n
                Que foi pesado assim\n\n
                Infância, o teu jardim\n
                Foi feito pra estragar`,
            }
        ],
    },
    {
        name: 'Trespasse',
        cover: cover_trespasse,
        release: '2023-05-25',
        tracks: [
            {
                name: 'Trespasse',
                video: 'hXwm4lZw5_k',
                lyrics: `
                São nove da manhã\n
                A hora enfim chegou\n
                Em cada passo um lamento\n
                E eu\n
                Me leva\n\n
                Do rosto de ninguém\n
                Sorriso algum sai\n
                Senhoras apertando o passo\n
                E eu\n
                Imóvel\n\n
                Cidade em procissão\n
                Só pra me enterrar\n
                O sorveteiro cruza a marcha\n
                E eu\n
                A pedra\n\n
                Instinto é viver\n
                Destino é acabar\n
                Funesta condição\n
                E eu...`,
            }
        ],
    },
    {
        name: 'Desmonte',
        cover: cover_desmonte,
        release: '2023-04-20',
        tracks: [
            {
                name: 'Desmonte',
                video: 'TAJ3YPIGP-k',
                lyrics: `
                Vai tudo acontecer\n
                E a gente nunca vai saber\n
                Se a vida quer um só caminho\n\n
                Ou um de cada vez\n
                Pra gente tanto fez\n
                Foi chegando a hora feito espinho\n\n
                Preceder e descobrir\n
                Como é que aprende isso?\n
                Receber e dividir\n\n
                Escolher\n
                Perder pra sempre\n\n
                Escolher\n
                Perder pra sempre\n
                O que ficou de fora dessa decisão\n\n
                Assumir\n
                Seguir em frente\n
                Deixar pra trás o que restou depois da ilusão\n\n
                O que restou depois da ilusão\n\n
                Escolher\n
                Perder pra sempre\n
                O que ficou de fora dessa decisão\n\n
                Assumir\n
                Seguir em frente\n
                Deixar pra trás o que restou depois da ilusão`,
            }
        ],
    },
    {
        name: 'Clutching The Edge',
        cover: cover_clutching,
        release: '2023-03-16',
        tracks: [
            {
                name: 'Clutching The Edge',
                video: 'B8eNw3ilD8k',
                lyrics: `
                I'm holding on\n
                Just by a thread\n
                I'm folding into one\n
                I'm holding on to dear life\n\n
                Stretching too far to stay alive\n
                This feeling has to stop\n
                I'm clutching the edge of the knife\n\n
                I'm holding on\n
                I'm breaking\n\n
                I'm breaking\n
                Collapsing\n
                Misplaced inside myself\n\n
                I'm breaking\n
                Collapsing\n
                Misplaced inside myself`,
            }
        ],
    },
    {
        name: 'Final',
        cover: cover_final,
        release: '2022-04-20',
        tracks: [
            {
                name: 'Remoto',
                video: 'M50T3VKbsIY',
                lyrics: `
                Não\n
                Vou mais\n
                Fingir\n
                Viver\n\n
                Se eu me levantar\n
                Só pra depois cair\n
                O que é que vão pensar de mim\n\n
                E se eu me arrastar\n
                Até me destruir\n
                Quem é que vai me olhar assim\n\n
                Distante sem saber\n
                Sozinho nessa multidão\n
                Com tudo pra escolher\n
                Mas nunca uma decisão\n\n
                Chega\n\n
                Não\n
                Vou mais\n
                Fingir\n
                Viver`,
            },
            {
                name: 'Visão Dupla',
                video: 'KzeS3Xk6U4U',
                lyrics: `
                Tudo cai no chão\n
                Até o céu e o mar tem fim, mas não\n
                Cabe no olhar\n
                Eu sei\n
                Que não é assim\n\n
                Tudo é invenção\n
                Quantos nós sobraram pra que eu\n
                Desate de uma vez\n
                Eu sei\n
                Que não é assim, mas\n
                Tudo é ilusão\n
                O céu, o mar, o chão e\n
                Eu\n\n
                Tão perto que\n
                Engana a vista\n
                Tão perto que\n
                Engana a vista\n
                Tão perto que\n
                Engana a vista`,
            },
            {
                name: 'Sintaxe',
                video: 'ZJWUjDXB4GU',
                lyrics: `
                Não\n
                Nem fala da derrota\n
                A gente nunca soube ganhar\n
                Desgarra da promessa\n
                Do cego ofício de acertar\n\n
                Sem\n
                Nunca mais\n
                Nunca mais\n
                Nunca mais\n
                Nunca mais tentar\n\n
                Nunca mais\n
                Nunca mais\n\n
                Não\n
                Cotuca essa ferida\n
                Deixa ela respirar\n
                Deixa ela respirar\n
                Deixa ela respirar\n
                Deixa ela respirar`,
            },
            {
                name: 'A Mosca e a Fogueira',
                video: 'CDriAIGXCtA',
                lyrics: `
                O lobo vem vestindo lã\n
                Olhos seguindo a luz que cega\n
                Assim\n
                A mosca e a fogueira\n
                O que é parar\n
                E ver o fim\n\n
                Incerto\n
                Mas preciso\n\n
                O tempo vai chegar pra nós\n
                Viver na mão do seu algoz\n
                Agride demais\n\n
                Coberto\n
                Sobreaviso\n
                O tempo vai traçar um fim\n
                E mesmo o mais fino cetim\n
                Agride`,
            },
            {
                name: 'Final',
                video: 'UZMlZTTVAnc',
                lyrics: `
                Chega\n
                Acabou\n
                Sempre esqueço de\n
                Por o ponto final\n\n
                (Aqui acho que a gente podia botar alguma conversa nossa\n
                Talvez sobre a música ter uma conversa no meio, saca?\n
                Hahahaha... Um lance bem meta e, se der, engraçado\n
                Que iria do riffão até aquela parte silenciosa\n
                E terminaria no build+riffão.)\n\n
                Chega\n
                Acabou\n
                Chega\n
                Acabou`,
            }
        ],
    }
];

export default releases;
import React, { useEffect, useRef, useState } from 'react';
import './index.sass';
import { hover } from '@testing-library/user-event/dist/hover';

const Slider: React.FC<any> = (props) => {
  
  const [pos, setPos] = useState(props.value || 0);
  const [down, setDown] = useState(false);
  const [hovering, setHovering] = useState(false);
  
  const ref = useRef<HTMLDivElement>(null);
  const refPos = useRef<number>(0);

  useEffect(() => {    
    ref.current?.addEventListener('mousedown', actionDown, false)   
    ref.current?.addEventListener('touchstart', actionDown, false);
    document.addEventListener('mousemove', updateValues);
    document.addEventListener('touchmove', updateValues);
    document.addEventListener('mouseup', actionUp);
    document.addEventListener('touchend', actionUp);
    return () => {
      ref.current?.removeEventListener('mousedown', actionDown)   
      ref.current?.removeEventListener('touchstart', actionDown);
      document.removeEventListener('mousemove', updateValues);
      document.removeEventListener('touchmove', updateValues);
      document.removeEventListener('mouseup', actionUp);
      document.removeEventListener('touchend', actionUp);      
    }
  }, [hovering, down, setPos])
  
  const getPosition = (ev:MouseEvent|TouchEvent) => {
    if (ref && ref.current) {
      const bdBox = ref.current.getBoundingClientRect();        
      const e:MouseEvent|Touch = ev instanceof TouchEvent ? ev.touches[0] : ev;
      return Math.min(Math.max(e.clientX - bdBox.left, 0), ref.current.clientWidth)/ref.current.clientWidth;
    }
    return 0;
  }
  
  const updateValues = (ev:MouseEvent|TouchEvent) => {     
    if (hovering) {      
      const newPosition = getPosition(ev);
      setPos(newPosition);
      refPos.current = newPosition;
      if (props.onPreviewValue) {
        props.onPreviewValue(newPosition);
      }
      if (down && props.onChangeValue) {
        props.onChangeValue(newPosition);
      }
    }
  }
  const actionDown = (ev:any) => {
    setHovering(true)
    setDown(true); 
    const newPosition = getPosition(ev);
    setPos(newPosition);
    refPos.current = newPosition;
    ev.preventDefault();
  }

  const actionUp = () => {
    if (down) {
      setHovering(false)
      setDown(false);       
      if (props.onChangeValue) {                
        props.onChangeValue(refPos.current);
      }
    }
  }


  return ( 
    <div className={`Slider ${hovering || down ? 'show' : ''}`} 
      ref={ref} 
      onMouseEnter={()=>{setHovering(true)}} 
      onMouseLeave={()=>{setHovering(false)}}
      >
        <div className='Slider__Track'></div>
        <div className='Slider__Cache transition-fast' style={{ width: `${(props.cache || 0) * 100}%` }}></div>
        <div className='Slider__Current' style={{ width: `${(down ? pos : props.value) * 100}%` }}>
          <div className='Slider__Pin transition-fast'></div>
        </div>
        <div className='Slider__Pin__Ghost transition-fast' style={{ left: `${((down || hovering) ? pos : props.value) * 100}%` }}>
          <div className='transition-fast'>{props.previewValue}</div>
        </div>
    </div>
  );
}

export default Slider;

import React, { useEffect, useRef } from 'react';
import './index.sass';
import anime from 'animejs';

const ControlsPlay: React.FC<any> = (props) => {

  const animeRef = useRef<SVGPolygonElement>(null);  
  const play = '12,10 12,15 12,22 13,22.2 17,20 24,16 24,16 17,12 13,9.5 12,10';
  const pause = '15,10 10,10 10,22 15,22 15,10 17,22 22,22 22,10 17,10 17,22';
 
  useEffect(() => {
    if (props.tracked && props.paused) {
      anime({
        targets: animeRef.current,
        points: play,
        easing: 'easeInOutSine',
        duration: 100,
      });
    } else if (props.tracked && !props.paused) {
      anime({
        targets: animeRef.current,
        points: pause,
        easing: 'easeInOutSine',
        duration: 100,
      });
    } else {
      anime({
        targets: animeRef.current,
        points: play,
        easing: 'easeInOutSine',
        duration: 100,
      });
    }
  }, [props.paused, props.tracked]);

  const handlePlayPause = () => {
    if (props.onPlayPause) props.onPlayPause();
  }

  return ( 
    <svg viewBox="0 0 32 32" className={`PlayButton ${props.filled ? 'filled' : ''} ${props.paused ? '' :  'playing'} ${props.tracked ? 'tracked' : ''}`} onClick={handlePlayPause}>
      <polygon ref={animeRef} fill="white"/>
      <circle cx="16" cy="16" r="15" fill='none' style={{ strokeDashoffset: 93 - ((props.pct||0)*93) }}/>
    </svg>
  );
}

const ControlsNext: React.FC<any> = (props) => {
  
  const handleTrack = (mod:number) => {
    if (props.onTrack && !props.disabled) props.onTrack(mod);
  }

  return ( 
    <svg viewBox="0 0 32 32" className={`${props.disabled ? 'disabled': ''}`} onClick={()=> { handleTrack(+1) }}>
      <polygon points="10,10 10,22 20,16 20,22 24,22 24,10 20,10, 20,16 10,10" fill="white"/>
    </svg>
  );
}

const ControlsPrevious: React.FC<any> = (props) => {
  
  const handleTrack = (mod:number) => {
    if (props.onTrack && !props.disabled) props.onTrack(mod);
  }

  return ( 
    <svg viewBox="0 0 32 32" className={`${props.disabled ? 'disabled': ''}`} onClick={()=> { handleTrack(-1) }}>
      <polygon points="10,10 10,22 14,22 14,16 24,22 24,10 14,16 14,10 10,10" fill="white"/>
    </svg>
  );
}

export { ControlsPlay, ControlsNext, ControlsPrevious };
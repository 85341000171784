import React from 'react';
import './index.sass';
import Links from '../links';

function Footer() {
  return (
    <div className="Footer">      
      <Links/>
    </div>
  );
}

export default Footer;

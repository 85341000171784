import React, { useEffect, useRef, useState } from 'react';
import './index.sass';
import Slider from '../Slider';
import { SoundtrackItem, SoundtrackPlaylist } from '../../models/soundModels';
import { calculateTime, getMetaData, getFilePath, getTrackDuration } from './tools'
import { ControlsNext, ControlsPlay, ControlsPrevious } from './controls';
import { cloneDeep } from 'lodash';

interface updateInfo {
  pct: number,
  isPaused: boolean,
}

interface IAudioPlayer {
  initialTrack?: number,
  playlist: {
    list: SoundtrackPlaylist,
    action: string,
    time: number,
  },
  onTrack?: (track:SoundtrackItem|null, audio:HTMLAudioElement) => void,
  onUpdate?: (data:updateInfo) => void,
  onUpdateList?: (list:SoundtrackPlaylist) => void,
  onMore?: (trck:SoundtrackItem) => void,
}


const AudioPlayer: React.FC<IAudioPlayer> = (props) => {

  // Tracking Events    
  navigator.mediaSession.setActionHandler('previoustrack', null );
  navigator.mediaSession.setActionHandler('previoustrack', () => { handleTracks(-1) });
  navigator.mediaSession.setActionHandler('nexttrack', null);
  navigator.mediaSession.setActionHandler('nexttrack', () => { handleTracks(1) });

  const selfRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  
  const [currentTrack, setCurrentTrack] = useState(props.initialTrack || 0);

  const [duration, setDuration] = useState(0.0);
  const [current, setCurrent] = useState(0.0);
  const [preview, setPreview] = useState(0.0);
  const [bufferedAmount, setBufferedAmount] = useState(0);

  const hasAudio = (callback:(audio:HTMLAudioElement)=>void) => {
    if (audioRef && audioRef.current) {
      // if (props.onTrack) props.onTrack(plst[currentTrack], audioRef.current);
      callback(audioRef.current);      
    }
  }

  // Update play info
  const handleLoaded = () => { 
    hasAudio((audio) => {
      setDuration(audio.duration);         
      try {
        setBufferedAmount(audio.buffered.end(audio.buffered.length - 1));
      } catch {}
    });
  }

  const handleTimeUpdate = () => {  
    hasAudio((audio) => {
      setCurrent(audio.currentTime/duration);  
      try {
        navigator.mediaSession.setPositionState({
          duration: audio.duration,
          playbackRate: audio.playbackRate,
          position: audio.currentTime
        });
      } catch {}   
      if (props.onUpdate) props.onUpdate({ isPaused: audio.paused, pct: current } );
      try {
        setBufferedAmount(audio.buffered.end(audio.buffered.length - 1));
      } catch {}   
    });
  }

  const handleChangeValue = (pct:number) => {
    hasAudio((audio) => {
      setCurrent(pct);
      audio.currentTime = pct * duration;
    });
  }

  // Player Controls
  const PlayPause = () => {   
    hasAudio((audio) => {      
      if (!audio.src) {
        try {
          setTrack(0);
          if (props.onTrack) props.onTrack(plst[0], audio);
        } catch {}
      }      
      if (!audio.paused) {
        try {
          audio.pause();
        } catch {}
      } else {
        try {
          audio.play();
        } catch {}
      }
    });
  }

  const setTrack = (track:number = currentTrack, isPlaying:boolean = false) => {
    hasAudio((audio) => {
      if (track !== currentTrack || !audio.src) {
        audio.src = getFilePath(plst[track]);                
        navigator.mediaSession.metadata = getMetaData(plst[track]);
        setCurrentTrack(track);
        if (props.onTrack) props.onTrack(plst[track], audio);
        if (isPlaying) PlayPause();
      }            
    });
  }

  const handleTracks = (mod:number, forcePlay:boolean = false) => {
    
    hasAudio((audio) => {
      const index = Math.abs((currentTrack + mod + plst.length) % plst.length);    
      if (mod < 0 && audio.currentTime >= 2) {
        audio.currentTime = 0;
        setCurrent(0)
      } else {
        if (props.onTrack) props.onTrack(plst[index], audio);
        setTrack(index, (!audio.paused || forcePlay)); 
      }    
    });
  }

  const openTracklist = () => {
    if (opened) {
      setHeight(0);
      setOpened(false);
    } else {
      setHeight(heightRef.current);
      setOpened(true);
    }
  }

  const handleMoreInfo = (t:SoundtrackItem) => {
    if (props.onMore) props.onMore(t);
  }

  const removeFromList = (i:number) => {
    hasAudio((audio) => {
      let prevIndex = currentTrack-1;
      const nPlst = cloneDeep(plst);
      nPlst.splice(i, 1);
      if (i === currentTrack) {
        audio.pause();
        setPlst(nPlst);
        handleTracks(+1);
        setCurrentTrack(i)
      } else if (i > currentTrack) {
        setPlst(nPlst);
      } else if (i < currentTrack) {
        setPlst(nPlst);
        setCurrentTrack(prevIndex)
      }
      if (props.onUpdateList) props.onUpdateList(nPlst);
    });
  }

  const heightRef = useRef<number>(0)
  const [height, setHeight] = useState(0);
  const [opened, setOpened] = useState(false);
  const [plst, setPlst] = useState<SoundtrackPlaylist>([])
  
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          heightRef.current = entry.contentRect.height;
          if (opened) {
            setHeight(heightRef.current);
          }          
        }
      }
    });
    if (listRef.current) resizeObserver.observe(listRef.current);

    if (props.playlist.time - new Date().getTime() < -20) return;    

    let incomingPlaylist:SoundtrackPlaylist = cloneDeep(props.playlist.list);
    incomingPlaylist.forEach(async (t, i) => {
      incomingPlaylist[i].duration = await getTrackDuration(t);
    });

    if (props.playlist.action === 'playnow') {
      const nPlaylist:SoundtrackPlaylist = [...incomingPlaylist, ...plst];
      setPlst(nPlaylist);
      hasAudio((audio) => {       
        setCurrent(0) // Reboots the audio time
        setCurrentTrack(props.initialTrack || 0);    
        audio.src = getFilePath(props.playlist.list[props.initialTrack || 0]);                
        navigator.mediaSession.metadata = getMetaData(props.playlist.list[props.initialTrack || 0]);
        audio.currentTime = 0;
        if (props.onTrack) props.onTrack(props.playlist.list[0], audio);
      }); 
    } else if (props.playlist.action === 'playnext') {
      const nPlaylist:SoundtrackPlaylist = [...plst];
      nPlaylist.splice(currentTrack+1, 0, ...incomingPlaylist);
      setPlst(nPlaylist);
    }
    
    
    return () => {
      if (listRef.current) resizeObserver.unobserve(listRef.current);
    }
    
  }, [props.playlist])

  return ( 
    <div className="AudioPlayer" ref={selfRef}>
      {plst.length && 
        <div className='AudioPlayer__Top'>
          <div className='AudioPlayer__Cover' onClick={()=>{ handleMoreInfo(plst[currentTrack]) }}>
            <img src={`covers/${plst[currentTrack].tag}.jpg`}/>
          </div>
          <div className='AudioPlayer__Title'>
            <div className='AudioPlayer__Name ellipsis'>{plst[currentTrack].name}</div>
            <div className='AudioPlayer__Description ellipsis'>{plst[currentTrack].album}</div>
          </div>
          <div className="AudioPlayer__Switch" onClick={()=>{ openTracklist() }}>
            <svg viewBox='0 0 32 32'>
              <polyline fill='none' points={opened ? '8,12 16,20 24,12' : '8,20 16,12 24,20'}/>
            </svg>
          </div>
          <div className='AudioPlayer__ShortControls'>
            <div className="AudioPlayer__SwitchMobile" onClick={()=>{ openTracklist() }}>
              <svg viewBox='0 0 32 32'>
                <polyline fill='none' points={opened ? '8,12 16,20 24,12' : '8,20 16,12 24,20'}/>
              </svg>
            </div>
            {
              !opened && <ControlsPlay tracked paused={audioRef.current?.paused} onPlayPause={PlayPause}/>
            }
          </div>
        </div>
      }
      <div className={`AudioPlayer__Time ${!opened ? 'closed' : ''}`}>
        <span>{calculateTime(current * duration)}</span>
        <Slider
          cache={bufferedAmount/duration} 
          previewValue={calculateTime(preview * duration)} 
          value={current}
          onPreviewValue={(val:number) => { setPreview(val) }} 
          onChangeValue={handleChangeValue}/>
        <span>{calculateTime(duration)}</span>
      </div>
      <div className='AudioPlayer__Controls'>
        <ControlsPrevious onTrack={handleTracks} disabled={plst.length === 1}/>
        <ControlsPlay tracked paused={audioRef.current?.paused} onPlayPause={PlayPause}/>
        <ControlsNext onTrack={handleTracks} disabled={plst.length === 1}/>
      </div>
      <div className={`AudioPlayer__List transition`} style={{ height }}>
        <div ref={listRef}>
          <div className='AudioPlayer__ControlsMobile' style={{ borderTop: '0' }}>
            <ControlsPrevious onTrack={handleTracks} disabled={plst.length === 1}/>
            <ControlsPlay tracked paused={audioRef.current?.paused} onPlayPause={PlayPause}/>
            <ControlsNext onTrack={handleTracks} disabled={plst.length === 1}/>
          </div>
          <div className='scrollable'>
            { plst.map( (t, i) => 
              <div key={`list_${t.album}_${t.name}_${i}`} className={`list_track ${i === currentTrack ? 'playing' : '' }`}>
                <div style={{ flex: '1', justifyContent: 'flex-start' }} onClick={() => {setTrack(i)}}>
                  <div>{i+1}</div>
                  <div>{t.name}</div>
                </div>
                <div>
                  <div onClick={() => {setTrack(i)}}>{calculateTime(t.duration)}</div>
                  <svg viewBox='0 0 32 32' onClick={() => {removeFromList(i)}}>
                    <polyline points='0,0 32,32'/>
                    <polyline points='0,32 32,0'/>
                  </svg>
                </div>      
              </div>
            )}
          </div>
        </div>
      </div>
      <audio ref={audioRef}
        preload='metadata'
        autoPlay
        onLoadedMetadata={handleLoaded}
        onTimeUpdate={handleTimeUpdate} 
        onEnded={()=>{plst.length > 1 ? handleTracks(+1, true) : audioRef.current?.play()}}
        ></audio>
    </div>
  );
}

export default AudioPlayer;

import Links from '../links';
import './index.sass';
import React from 'react';

function Wait() {

  const openVideo = () => {
    window.open('https://youtu.be/CRFuawcQwoc', 'blank')
  }

  return (
    <div className="Top">
      <div className='Release'>
        <div className='Wrapper'>
          <video autoPlay={true} loop={true} src={'videos/bg.mp4'} muted/> 
        </div>
        <Links/>
        <div className='Lettering'>
          <div className='artist'> OSSAMENTA</div>
          <div className='title'>NASCE<br/>DOURO</div>
          <div className='lf'><a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/alcachofra.co/' target='blank'>ALCACHOFRA CO.</a> E <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/bonoboai/' target='blank'>BONOBO</a> <span>APRESENTAM</span> NASCEDOURO</div>
          <div className='lf'><span>DIRIGIDO POR</span> <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/isamaiaa/' target='blank'>ISADORA MAIA</a> E <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/rodrigourbano/' target='blank'>RODRIGO URBANO</a> <span>PRODUZIDO POR</span> <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/alcachofra.co/' target='blank'>ALCACHOFRA CO.</a> E <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/bonoboai/' target='blank'>BONOBO</a> <span>MÚSICA E LETRA POR</span> OSSAMENTA <span>PROMPT ARTIST</span> <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/j_zaqq/' target='blank'>JONATHAN ZAQUE</a></div>
          <div className='lf'><span>EDIÇÃO POR</span> <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/isamaiaa/' target='blank'>ISADORA MAIA</a> <span>IMAGENS</span> <a rel="noopener noreferrer" className='transition' href='https://www.midjourney.com/' target='blank'>MIDJOURNEY</a> <span>MOVIMENTO</span> <a rel="noopener noreferrer" className='transition' href='https://runwayml.com/' target='blank'>RUNWAY</a> <span>DIREÇÃO DE ARTE</span> OSSAMENTA <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/alcachofra.co/' target='blank'>ALCACHOFRA CO.</a> <a rel="noopener noreferrer" className="transition" href='https://www.instagram.com/bonoboai/' target='blank'>BONOBO</a></div>
        </div>
        <div className='actions'>
          <button onClick={openVideo}>Assistir</button>
        </div>
      </div>
    </div>
  );
}

export default Wait;

import { SoundtrackItem } from '../../models/soundModels';

const calculateTime: (secs:number|undefined) => string = (secs = 0) => {
  if (secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }
  return `0:00`;
}

const getMetaData = (item:SoundtrackItem) => {
    return new MediaMetadata({
        title: item.name,
        artist: 'Ossamenta',
        album: item.album,
        artwork:[
          { src: `covers/${item.tag}.jpg`, sizes: "96x96", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "128x128", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "256x256", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "384x384", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "512x512", type: "image/jpg" },
        ]
      });
}

const getFilePath = (item:SoundtrackItem) => {
    return `audio/mp3/${(item.name === item.album ? item.tag : item.name).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.mp3`;
}

const numberToFloat = (number:number) => {
    return parseFloat((number).toFixed(2));   
}

const getTrackDuration = (item:SoundtrackItem) => {
  return new Promise<number>(resolve => {
    const audio = new Audio();
    if (audio) {
      audio.onloadedmetadata = () => {
        audio.remove();
        resolve(audio.duration);
      }
      audio.src = getFilePath(item);
    }    
  });
}

export {
    getTrackDuration,
    calculateTime,
    getMetaData,
    getFilePath,
    numberToFloat
}